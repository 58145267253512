import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import copyToClipboard from "../../utils/clipboardUtils";
import { formatDate } from "../../utils/helpers";
import BookingTab from "./BookingTab";
import SendCustomServiceDialog from "./components/SendCustomServiceDialog";
import "./css/UserDetailsPage.css";

const UserDetailsPage = () => {
  const location = useLocation();
  const userId = location.state && location.state.userId;
  const [loading, setLoading] = useState(true);
  const [sendingOffer, setSendingOffer] = useState(false);
  const [showCustomServiceDialog, setShowCustomServiceDialog] = useState(false);

  const [user, setUser] = useState(null);
  const [totalBooked, setTotalBooked] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient.request(
          `user/details?userId=${userId}`,
          "GET"
        );
        if (response) {
          if (response.user) {
            setUser(response.user);
          }
          if (response.totalBooked) {
            setTotalBooked(response.totalBooked);
          }
          if (response.totalCompleted) {
            setTotalCompleted(response.totalCompleted);
          }
          if (response.totalCancelled) {
            setTotalCancelled(response.totalCancelled);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchUser();
  }, [userId]);

  const handleSendCustomService = async (serviceId, duration) => {
    if (showCustomServiceDialog) {
      setShowCustomServiceDialog(false);
    }
    if (!serviceId || !duration) {
      toast.error("Please select service and duration");
      return;
    }
    if (!user._id) {
      toast.error("User not found");
      return;
    }
    setSendingOffer(true);
    const payload = {
      userId: user._id,
      serviceId,
      duration,
    };
    try {
      const response = await apiClient.request(
        "custom-service/save",
        "POST",
        payload
      );
      if (response) {
        toast.success("Offer sent successfully");
      }
    } catch (error) {
      toast.error("Failed to send offer");
    }
    setSendingOffer(false);
  };

  if (!userId) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        No data found
      </h5>
    );
  }

  if (loading) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        Loading...
      </h5>
    );
  }

  return (
    <>
      <div className="d-flex">
        <div className="user-left-container">
          <div className="user-detail-row mb-5">
            <Link to="/admin/users">
              <FaArrowLeft color="#000" />
            </Link>
          </div>
          <div>
            <div>{user?.name ?? "-"}</div>
            <div
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(user?.email ?? "-")}
            >
              {user?.email ?? "-"}
            </div>
            <div
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(user?.phone ?? "-")}
            >
              {user?.phone ?? "-"}
            </div>
          </div>
          <hr className="service-divider" />
          <h6>Joined on</h6>
          <div className="mb-4">{formatDate(user?.createdAt)}</div>
          <h6>Booked</h6>
          <div className="mb-4">{totalBooked ?? "-"}</div>
          <h6>Completed</h6>
          <div className="mb-4">{totalCompleted ?? "-"}</div>
          <h6>Cancelled</h6>
          <div>{totalCancelled ?? "-"}</div>
          <div className="send-offer-container">
            <button
              className="send-offer-button"
              disabled={sendingOffer}
              onClick={() => {
                setShowCustomServiceDialog(true);
              }}
            >
              Send Custom Offer
            </button>
            <SendCustomServiceDialog
              show={showCustomServiceDialog}
              handleClose={() => setShowCustomServiceDialog(false)}
              onCancel={() => setShowCustomServiceDialog(false)}
              onSubmit={handleSendCustomService}
            />
          </div>
        </div>

        <div className="wrapper">
          <div className="customHeader">
            <h5>User History</h5>
            <hr />
          </div>
          <BookingTab userId={userId} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserDetailsPage;
