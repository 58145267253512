import React, { useContext, useRef, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineDownload,
  AiOutlineUpload,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { BsCopy } from "react-icons/bs";
import { CiMenuKebab } from "react-icons/ci";
import { RxOpenInNewWindow } from "react-icons/rx";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AlertDialog from "../../../components/alerts/AlertDialog";
import CustomAlertDialog from "../../../components/alerts/CustomAlertDialog";
import { BookingContext } from "../../../contexts/BookingContext";
import { formatDate } from "../../../utils/dateUtils";
import { capitalizeFirstLetter } from "../../../utils/helpers";
import StarRating from "./StarRating";

const BookingDetails = ({ booking, onClose }) => {
  const {
    uploadReceipt,
    handleRawFilesUpload,
    handleFinalFilesUpload,
    handleShootCompleted,
    handleextendDeliverable,
    handleMarkBookingCompleted,
    handleMoveToBooked,
    handleStatusChange,
    updatePhotographerPayment,
  } = useContext(BookingContext);
  const fileInputRef = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [showFinalFileAlert, setShowFinalFileAlert] = useState(false);
  const [showExtendAlert, setExtendAlert] = useState(false);
  const [showPhotographerPaymentAlert, setPhotographerPaymentAlert] =
    useState(false);

  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleConfirm = () => {
    handleMoveToBooked(booking._id, booking.photographerId._id);
    setShowAlert(false);
  };

  const handleStatusChangeConfirm = (status) => {
    handleStatusChange(booking._id, status);
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const renderCustomAlertDialog = (status, message, nextStatus) => (
    <CustomAlertDialog
      show={showAlert}
      handleClose={handleCancel}
      title="Confirmation"
      subtitle={`Are you sure you want to move this booking to '${message}'?`}
      buttonText="Yes"
      onCancel={handleCancel}
      onClick={() =>
        status === "PARTNER_ASSIGNED"
          ? handleConfirm()
          : handleStatusChangeConfirm(nextStatus)
      }
      isDanger
    />
  );

  const handleReceiptChange = async (e) => {
    const file = e.target.files[0];
    await uploadReceipt(booking, file);
  };

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleKebabClick = () => {
    setShowDropdown(!showDropdown);
  };

  const renderDropdown = () => {
    switch (booking.status) {
      case "PROJECT_COMPLETED":
        return (
          <div className="booking-dropdown">
            <p>Move to</p>
            <p className="cursor-pointer" onClick={() => setShowAlert(true)}>
              Final Files Uploaded
            </p>
            {showAlert &&
              renderCustomAlertDialog(
                booking.status,
                "Final Files Uploaded",
                "EDITED_READY"
              )}
          </div>
        );
      case "EDITED_READY":
        return (
          <div className="booking-dropdown">
            <p>Move to</p>
            <p className="cursor-pointer" onClick={() => setShowAlert(true)}>
              Raw Files Uploaded
            </p>
            {showAlert &&
              renderCustomAlertDialog(
                booking.status,
                "Raw Files Uploaded",
                "RAW_UPLOADED"
              )}
          </div>
        );
      case "RAW_UPLOADED":
        return (
          <div className="booking-dropdown">
            <p>Move to</p>
            <p className="cursor-pointer" onClick={() => setShowAlert(true)}>
              Shoot Completed
            </p>
            {showAlert &&
              renderCustomAlertDialog(
                booking.status,
                "Shoot Completed",
                "SHOOT_COMPLETED"
              )}
          </div>
        );
      case "PARTNER_ASSIGNED":
        return (
          <div className="booking-dropdown">
            <p>Move to</p>
            <p className="cursor-pointer" onClick={() => setShowAlert(true)}>
              Booked
            </p>
            {showAlert &&
              renderCustomAlertDialog(booking.status, "Booked", "BOOKED")}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="booking-details open">
        <div className="header">
          <AiOutlineClose className="icon" size={25} onClick={onClose} />
          <h5 className="centered-title">Booking - {booking.customId}</h5>
          {booking.status !== "BOOKED" &&
            booking.status !== "SHOOT_COMPLETED" && (
              <div>
                <CiMenuKebab
                  color="var(--secondary-color)"
                  className="cursor-pointer"
                  size={25}
                  onClick={handleKebabClick}
                />
                {showDropdown && renderDropdown()}
              </div>
            )}
        </div>
        <hr />
        <div className="section">
          <p>
            <strong>Status</strong> {capitalizeFirstLetter(booking.status)}
          </p>
        </div>
        {booking.rawFilesLink && (
          <div>
            <hr />
            <div className="section">
              <div className="header">Deliverables Details</div>
            </div>
            <p>
              <strong>Raw files Link</strong>
              <a
                href={booking.rawFilesLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                Link
              </a>
            </p>
            {booking.finalFilesLink && (
              <p>
                <strong>Final files Link</strong>
                <a
                  href={booking.finalFilesLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Link
                </a>
              </p>
            )}
          </div>
        )}
        {booking.photographerId && (
          <div>
            <hr />
            <div className="section">
              <div className="header">
                <p>Partner Details</p>
                <div>
                  <a
                    href={`https://wa.me/${booking.photographerId.personalDetails.countryCode}${booking.photographerId.personalDetails.mobileNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp
                      className="icon"
                      size={25}
                      color="#55D062"
                      style={{ marginRight: "10px" }}
                    />
                  </a>
                  <Link
                    to={`/admin/photographers/${booking.photographerId.personalDetails.customId}`}
                  >
                    <RxOpenInNewWindow
                      className="icon"
                      size={25}
                      color="var(--secondary-color)"
                    />
                  </Link>
                </div>
              </div>
              <p>
                <strong>Partner Name</strong>
                {booking.photographerId.personalDetails.name}
              </p>
              <p>
                <strong>Partner ID</strong>
                {booking.photographerId.personalDetails.customId}
              </p>
              <p>
                <strong>Email</strong>
                {booking.photographerId.personalDetails.email}
              </p>
              <p>
                <strong>Phone number</strong>
                {booking.photographerId.personalDetails.countryCode}
                {booking.photographerId.personalDetails.mobileNumber}
              </p>
            </div>
          </div>
        )}
        <hr />
        <div className="section">
          <div className="header">
            <p>Package Details</p>
          </div>
          {booking.package && (
            <>
              {booking.package.filter && (
                <p>
                  <strong>Filter</strong> {booking.package.filter}
                </p>
              )}
              {booking.package.name && (
                <p>
                  <strong>Name</strong> {booking.package.name}
                </p>
              )}
              {booking.package.regularPrice && (
                <p>
                  <strong>Regular Price</strong> ₹{" "}
                  {booking.package.regularPrice}
                </p>
              )}
              {booking.package.salePrice && (
                <p>
                  <strong>Sale Price</strong> ₹ {booking.package.salePrice}
                </p>
              )}
              {booking.package.shootDuration && (
                <p>
                  <strong>Shoot Duration</strong>{" "}
                  {booking.package.shootDuration}
                </p>
              )}
              {booking.package.packageDetails &&
                booking.package.packageDetails.length > 0 && (
                  <div>
                    <strong>Package Details</strong>
                    {booking.package.packageDetails.map((detail, index) => (
                      <div key={index}>
                        {index + 1}. {detail.name} -{" "}
                        {detail.available ? "Available" : "Not Available"}
                      </div>
                    ))}
                  </div>
                )}
              {booking.package.deliveryDay && (
                <p>
                  <strong>Delivery Day</strong> {booking.package.deliveryDay}
                </p>
              )}
              {booking.package.showRange !== undefined && (
                <p>
                  <strong>Show Range</strong>{" "}
                  {booking.package.showRange ? "Yes" : "No"}
                </p>
              )}
            </>
          )}
        </div>
        <hr />
        <div className="section">
          <div className="header">
            <p>Shoot Details</p>
            <BsCopy className="cursor-pointer" color="var(--secondary-color)" />
          </div>
          <p>
            <strong>Status</strong> {capitalizeFirstLetter(booking.status)}
          </p>
          <p>
            <strong>Service</strong> {booking.serviceName} |{" "}
            {booking.packageName}
          </p>
          {booking && booking.fas && booking.fas.length > 0 && (
            <div>
              <strong>FAS</strong>
              {booking.fas.map((f, index) => (
                <div key={index}>
                  {index + 1}. {f.title || ""}
                </div>
              ))}
            </div>
          )}
          <p>
            <strong>Booked On</strong>
            {formatDate(booking.createdAt)}
          </p>
          <p>
            <strong>Shoot Date</strong>
            {formatDate(booking.bookingDate)}
          </p>
          <p>
            <strong>Time Slot</strong>
            {booking.timeSlot}
          </p>
          <p>
            <strong>Shoot Location</strong>
            <span className="text-right-overflow">
              <span>{booking.locationName}</span>
            </span>
          </p>

          {booking.city && (
            <p>
              <strong>City</strong>
              <span className="text-right-overflow">
                <span>{booking.city}</span>
              </span>
            </p>
          )}

          {booking.locality && (
            <p>
              <strong>Locality</strong>
              <span className="text-right-overflow">
                <span>{booking.locality}</span>
              </span>
            </p>
          )}

          {booking.directionToReach && (
            <p>
              <strong>Direction To Reach</strong>
              <span className="text-right-overflow">
                <span>{booking.directionToReach}</span>
              </span>
            </p>
          )}
          {booking.locationType && (
            <p>
              <strong>Location Type</strong>
              <span className="text-right-overflow">
                <span>{booking.locationType}</span>
              </span>
            </p>
          )}
          {booking.customizationOption &&
            Array.isArray(booking.customizationOption) &&
            booking.customizationOption.length > 0 && (
              <>
                <p>
                  <strong>Customization option</strong>
                </p>
                {booking.customizationOption.map((option, index) => (
                  <p key={index}>
                    {index + 1}. {option.optionName}
                  </p>
                ))}
              </>
            )}
        </div>
        <hr />
        {booking.rating && (
          <>
            <div className="section">
              <div className="header">
                <p>Ratings</p>
              </div>
              <p>
                <strong>Professionalism</strong>
                <StarRating rating={booking.rating.professionalism} />
              </p>
              <p>
                <strong>Punctuality</strong>
                <StarRating rating={booking.rating.punctuality} />
              </p>
              <p>
                <strong>Communication</strong>
                <StarRating rating={booking.rating.communication} />
              </p>
              <p>
                <strong>Overall</strong>
                <StarRating rating={booking.rating.overall} />
              </p>
              <p>
                <strong>Review</strong> {booking.rating.review}
              </p>
            </div>
            <hr />
          </>
        )}
        <div className="section">
          <div className="header">
            <p>User Details</p>
            <div>
              <a
                href={`https://wa.me/${booking.userPhone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineWhatsApp
                  className="icon"
                  size={25}
                  color="#55D062"
                  style={{ marginRight: "10px" }}
                />
              </a>
              <RxOpenInNewWindow
                className="icon"
                size={25}
                color="var(--secondary-color)"
              />
            </div>
          </div>
          <p>
            <strong>User Name</strong> {booking.userName}
          </p>
          <p>
            <strong>Email</strong> {booking.userEmail}
          </p>
          <p>
            <strong>Phone number</strong>
            {booking.userPhone}
          </p>
        </div>
        <hr />
        <div className="section">
          <div className="header">
            <p>Bill Details</p>
            <div>
              <>
                <AiOutlineUpload
                  className="icon"
                  size={25}
                  color="#26ABE2"
                  onClick={handleFileInputClick}
                />
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleReceiptChange}
                />
              </>
              {booking.receiptUrl && (
                <a
                  href={booking.receiptUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiOutlineDownload
                    className="icon"
                    size={25}
                    color="#26ABE2"
                  />
                </a>
              )}
            </div>
          </div>
          {booking.costBreakdown.map((item, index) => (
            <p key={index}>
              <strong>{item.title}</strong> ₹ {item.price}
            </p>
          ))}
        </div>
        {booking.status === "BOOKED" &&
          booking &&
          booking._id &&
          booking.bookingDate && (
            <Link
              to="/admin/assign-partner"
              state={{
                bookingDate: booking.bookingDate,
                timeSlot: booking.timeSlot,
                shootLocation: booking.locationId.locationName,
                bookingId: booking._id,
              }}
            >
              <button className="button-primary">Assign Partner</button>
            </Link>
          )}
        {booking.status === "PARTNER_ASSIGNED" &&
          booking &&
          booking._id &&
          booking.photographerId &&
          booking.photographerId._id && (
            <button
              className="button-primary"
              onClick={() =>
                handleShootCompleted(booking._id, booking.photographerId._id)
              }
            >
              Move to Shoot completed
            </button>
          )}
        {booking.status === "SHOOT_COMPLETED" && booking && booking._id && (
          <div>
            <button
              className="button-primary"
              onClick={() => setShowAlertDialog(true)}
            >
              Add raw files link
            </button>
            <AlertDialog
              show={showAlertDialog}
              handleClose={() => setShowAlertDialog(false)}
              title="Add raw files link"
              buttonText={booking.rawFilesLink ? "Update" : "Add"}
              hint="Add raw files link"
              value={booking.rawFilesLink ? booking.rawFilesLink : ""}
              onCancel={() => setShowAlertDialog(false)}
              onClick={(link) => handleRawFilesUpload(booking._id, link)}
            />
          </div>
        )}
        {booking.status === "RAW_UPLOADED" && booking && booking._id && (
          <div>
            <button
              className="button-primary"
              onClick={() => setShowFinalFileAlert(true)}
            >
              Add final files link
            </button>
            <AlertDialog
              show={showFinalFileAlert}
              handleClose={() => setShowFinalFileAlert(false)}
              title="Add final files link"
              buttonText={booking.finalFilesLink ? "Update" : "Add"}
              hint="Add final files link"
              value={booking.finalFilesLink ? booking.finalFilesLink : ""}
              onCancel={() => setShowFinalFileAlert(false)}
              onClick={(link) => handleFinalFilesUpload(booking._id, link)}
            />
            <button
              className="button-secondary"
              onClick={() => setShowAlertDialog(true)}
            >
              Edit raw files link
            </button>
            <AlertDialog
              show={showAlertDialog}
              handleClose={() => setShowAlertDialog(false)}
              title="Edit raw files link"
              buttonText={booking.rawFilesLink ? "Update" : "Add"}
              hint="Edit raw files link"
              value={booking.rawFilesLink ? booking.rawFilesLink : ""}
              onCancel={() => setShowAlertDialog(false)}
              onClick={(link) => handleRawFilesUpload(booking._id, link)}
            />
            <button
              className="button-secondary"
              onClick={() => setExtendAlert(true)}
            >
              Extend deliverables deadline
            </button>
            <AlertDialog
              show={showExtendAlert}
              handleClose={() => setExtendAlert(false)}
              title="Extend deadline"
              buttonText="Extend"
              hint="Extend deadline"
              value={
                booking.extendedDeliverableMessage
                  ? booking.extendedDeliverableMessage
                  : ""
              }
              onCancel={() => setExtendAlert(false)}
              onClick={(message) => {
                setExtendAlert(false);
                return handleextendDeliverable(booking._id, message);
              }}
            />
          </div>
        )}
        {booking.status === "EDITED_READY" && booking && booking._id && (
          <div>
            <button
              className="button-primary"
              onClick={() => setShowFinalFileAlert(true)}
            >
              View final files link
            </button>
            <AlertDialog
              show={showFinalFileAlert}
              handleClose={() => setShowFinalFileAlert(false)}
              title="Update final files link"
              buttonText={booking.finalFilesLink ? "Update" : "Add"}
              hint="Update final files link"
              value={booking.finalFilesLink ? booking.finalFilesLink : ""}
              onCancel={() => setShowFinalFileAlert(false)}
              onClick={(link) => handleFinalFilesUpload(booking._id, link)}
            />
            <button
              className="button-secondary"
              onClick={() => setShowAlertDialog(true)}
            >
              Edit raw files link
            </button>
            <AlertDialog
              show={showAlertDialog}
              handleClose={() => setShowAlertDialog(false)}
              title="Edit raw files link"
              buttonText={booking.rawFilesLink ? "Update" : "Add"}
              hint="Edit raw files link"
              value={booking.rawFilesLink ? booking.rawFilesLink : ""}
              onCancel={() => setShowAlertDialog(false)}
              onClick={(link) => handleRawFilesUpload(booking._id, link)}
            />
            <button
              className="button-secondary"
              onClick={() => setExtendAlert(true)}
            >
              Mark booking as completed
            </button>
            <CustomAlertDialog
              show={showExtendAlert}
              handleClose={() => setExtendAlert(false)}
              title="Mark booking as completed"
              buttonText="Complete"
              subtitle="Mark booking as completed"
              onCancel={() => setExtendAlert(false)}
              onClick={() => {
                setExtendAlert(false);
                handleMarkBookingCompleted(booking._id);
              }}
            />
          </div>
        )}
        {booking.status === "PROJECT_COMPLETED" && booking.finalFilesLink && (
          <button
            className="button-primary"
            onClick={() => window.open(booking.finalFilesLink, "_blank")}
          >
            View final files
          </button>
        )}
        {booking.status === "PROJECT_COMPLETED" &&
          booking.photographerPayment === "pending" && (
            <>
              <button
                className="button-secondary"
                onClick={() => setPhotographerPaymentAlert(true)}
              >
                Mark paid to partner
              </button>
              <CustomAlertDialog
                show={showPhotographerPaymentAlert}
                handleClose={() => setPhotographerPaymentAlert(false)}
                title="Mark paid to partner"
                buttonText="Pay"
                subtitle="This will mark the payment as paid to the partner. Are you sure?"
                onCancel={() => setPhotographerPaymentAlert(false)}
                onClick={() => {
                  setPhotographerPaymentAlert(false);
                  updatePhotographerPayment(booking._id);
                }}
              />
            </>
          )}
      </div>
      <ToastContainer />
    </>
  );
};

export default BookingDetails;
