import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import SearchAndPagination from "../../components/SearchAndPagination";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const AllUsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeletedUsers, setShowDeletedUsers] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleDeletedUsers = () => {
    setShowDeletedUsers(!showDeletedUsers);
  };

  useEffect(() => {
    const fetchUsers = async (searchTerm = "", page = 1) => {
      try {
        const response = await apiClient.request(
          `user/all?phone=${searchTerm}&page=${page}&deletedUser=${showDeletedUsers}`,
          "GET"
        );
        if (response && response.users) {
          setUsers(response.users);
          if (response.totalPages) {
            setTotalPages(response.totalPages);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers(debouncedSearchTerm, currentPage);
  }, [debouncedSearchTerm, currentPage, showDeletedUsers]);

  return (
    <>
      <div className="px-4 d-flex justify-content-between align-items-center">
        <SearchAndPagination
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          placeholder={"Search by phone number"}
        />
        <Form.Check
          type="switch"
          id="custom-switch"
          label={showDeletedUsers ? "Hide Deleted Users" : "Show Deleted Users"}
          checked={showDeletedUsers}
          onChange={toggleDeletedUsers}
        />
      </div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>User</th>
            <th>Booking Completed</th>
          </tr>
        </thead>
        <tbody>
          {(users || []).map((user) => (
            <tr key={user._id}>
              <td
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/admin/users-details`, {
                    state: { userId: user._id },
                  });
                }}
              >
                <div>{user.name}</div>
                <div>{user.email}</div>
                <div>{user.phone}</div>
              </td>
              <td>{user.totalCompletedBookings}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ToastContainer />
    </>
  );
};

export default AllUsersPage;
